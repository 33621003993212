import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7ac62b2a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "designer-worker"
};
var _hoisted_2 = {
  class: "design-options-content"
};
var _hoisted_3 = {
  class: "design-options-top",
  id: "design-options-top"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "design-options-Bottom"
};
var _hoisted_6 = {
  class: "design-options-bottom",
  id: "design-options-bottom"
};
var _hoisted_7 = ["src"];
import { onMounted } from "vue";
export default {
  __name: 'designers',
  setup: function setup(__props) {
    var seamless = require("seamscroll");
    var designers = [{
      id: 0,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers01.png"
    }, {
      id: 1,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers02.png"
    }, {
      id: 2,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers03.png"
    }, {
      id: 3,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers04.png"
    }, {
      id: 4,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers05.png"
    }, {
      id: 5,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers06.png"
    }];
    var designersBottom = [{
      id: 0,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers07.png"
    }, {
      id: 1,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers08.png"
    }, {
      id: 2,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers09.png"
    }, {
      id: 3,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers10.png"
    }, {
      id: 4,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers11.png"
    }, {
      id: 5,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/home/designers12.png"
    }];
    onMounted(function () {
      seamlessImgs();
    });
    var seamlessImgs = function seamlessImgs() {
      // 向左滑动
      seamless.init({
        dom: document.getElementById("design-options-top"),
        direction: 2
      });
      // 向右滑动
      seamless.init({
        dom: document.getElementById("design-options-bottom"),
        direction: 3
      });
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(designers, function (item) {
        return _createElementVNode("div", {
          key: item.id,
          class: "design-img"
        }, [_createElementVNode("img", {
          class: "img",
          src: item.img,
          alt: ""
        }, null, 8, _hoisted_4)]);
      }), 64))])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(designersBottom, function (item) {
        return _createElementVNode("div", {
          key: item.id,
          class: "design-bottom-img"
        }, [_createElementVNode("img", {
          class: "img",
          src: item.img,
          alt: ""
        }, null, 8, _hoisted_7)]);
      }), 64))])])]);
    };
  }
};