import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1185552a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "designer-worker"
};
var _hoisted_2 = {
  class: "design-options-content"
};
var _hoisted_3 = {
  class: "design-options-top",
  id: "design-options-top"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "design-options-Bottom"
};
var _hoisted_6 = {
  class: "design-options-bottom",
  id: "design-options-bottom"
};
var _hoisted_7 = ["src"];
import { onMounted } from "vue";
export default {
  __name: 'designer-worker',
  setup: function setup(__props) {
    var seamless = require("seamscroll");
    var designerImgs = [{
      id: 0,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs1.png"
    }, {
      id: 1,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs2.png"
    }, {
      id: 2,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs3.png"
    }, {
      id: 3,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs4.png"
    }, {
      id: 4,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs5.png"
    }, {
      id: 5,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs6.png"
    }, {
      id: 6,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs7.png"
    }, {
      id: 7,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs8.png"
    }, {
      id: 8,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs1.png"
    }, {
      id: 9,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs2.png"
    }, {
      id: 10,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs3.png"
    }];
    var designerImgsBottom = [{
      id: 0,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs1.png"
    }, {
      id: 1,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs2.png"
    }, {
      id: 2,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs3.png"
    }, {
      id: 3,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs4.png"
    }, {
      id: 4,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs5.png"
    }, {
      id: 5,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs6.png"
    }, {
      id: 6,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs7.png"
    }, {
      id: 7,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs8.png"
    }, {
      id: 8,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs1.png"
    }, {
      id: 9,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs2.png"
    }, {
      id: 10,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/global-website/designerImgs/designerImgs3.png"
    }];
    onMounted(function () {
      seamlessImgs();
    });
    var seamlessImgs = function seamlessImgs() {
      // 向左滑动
      seamless.init({
        dom: document.getElementById("design-options-top"),
        direction: 2
      });
      // 向右滑动
      seamless.init({
        dom: document.getElementById("design-options-bottom"),
        direction: 3
      });
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(designerImgs, function (item) {
        return _createElementVNode("div", {
          key: item.id,
          class: "design-img"
        }, [_createElementVNode("img", {
          class: "img",
          src: item.img,
          alt: ""
        }, null, 8, _hoisted_4)]);
      }), 64))])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(designerImgsBottom, function (item) {
        return _createElementVNode("div", {
          key: item.id,
          class: "design-bottom-img"
        }, [_createElementVNode("img", {
          class: "img",
          src: item.img,
          alt: ""
        }, null, 8, _hoisted_7)]);
      }), 64))])])]);
    };
  }
};